import React from 'react'
import * as yup from 'yup'

import { Input } from '../../../common/form/fields'
import { Alert, Button, PageHeader } from '../../../common'
import { useForm, useChangePassword } from '../../../../hooks'

import * as styles from './resetPassword.module.scss'
import { Severity } from '../../../../types'

const schema = yup.object().shape({
   oldPassword: yup
      .string()
      .required('The existing password field is required.'),
   newPassword: yup
      .string()
      .required(
         'Your password requires at least 8 characters, minimum one upper case'
      ),
   confirmPassword: yup
      .string()
      .required('The confirm new password field is required.')
      .oneOf(
         [yup.ref('newPassword'), null],
         'The new password and confirm new password do not match.'
      ),
})

const ResetPassword = () => {
   const { error, isLoading, isSuccess, save } = useChangePassword()
   const { onSubmit, validationErrors } = useForm(schema, (values) => {
      save({
         oldpassword: values.oldPassword,
         newpassword: values.newPassword,
         confirmpassword: values.confirmPassword,
      })
   })

   return (
      <>
         <PageHeader title="Reset Password" extraMargin />
         <p>
            To change your password enter your existing one, a new one and then
            confirm it.
         </p>

         <form noValidate onSubmit={onSubmit} className={styles.wrapper}>
            <Input
               id="oldPassword"
               label="Existing Password"
               error={validationErrors.oldPassword}
               inputProps={{
                  type: 'password',
               }}
               required
            />

            <hr />

            <Input
               id="newPassword"
               description="At least 8 characters, minimum one upper case"
               label="New Password"
               error={validationErrors.newPassword}
               inputProps={{
                  type: 'password',
               }}
               required
            />
            <Input
               id="confirmPassword"
               label="Confirm New Password"
               error={validationErrors.confirmPassword}
               inputProps={{
                  type: 'password',
               }}
               required
            />

            {error && <Alert message={error} />}
            {isSuccess && (
               <Alert
                  message="Your password has been changed successfully"
                  severity={Severity.SUCCESS}
               />
            )}

            <Button loading={isLoading}>Save</Button>
         </form>
      </>
   )
}

export default ResetPassword
